import type {
    ObjectShorthandValue,
    ProjectTileProps,
} from '@archipro-design/aria';
import { ProjectTile } from '@archipro-design/aria';
import { useEffect, useRef, useState } from 'react';
import type { LogImpressionProps } from '@archipro-website/tracker';
import { useLogImpression, useTracker } from '@archipro-website/tracker';
import { useInViewport } from 'ahooks';

export type TileProps = ObjectShorthandValue<ProjectTileProps> & {
    impression?: LogImpressionProps;
    sectionSource?: 'Directory' | 'Profile' | 'Search';
};

const ProjectImpressionTile = (props: TileProps) => {
    const { impression, sectionSource, ...rest } = props;
    const ref = useRef<HTMLDivElement>(null);

    useLogImpression({ data: impression, ref });
    const tracker = useTracker();

    const [inViewport] = useInViewport(ref, {
        threshold: 0,
    });

    const [beenInViewport, setBeenInViewport] = useState(inViewport);

    useEffect(() => {
        if (inViewport && !beenInViewport) {
            setBeenInViewport(true);
        }
    }, [inViewport, beenInViewport]);

    const carouselTracker = (direction: 'next' | 'prev') => {
        if (impression) {
            switch (direction) {
                case 'next':
                    tracker.log('CarouselNext', {
                        url: new URL(window.location.href),
                        targetTracker: ['archiproTracker', 'ga4Tracker'],
                        data: {
                            ExtraData: JSON.stringify({
                                source: sectionSource,
                                libraryItemId: impression.itemID,
                            }),
                        },
                        ga4Data: {
                            source: sectionSource,
                            libraryItemId: impression.itemID,
                        },
                    });
                    break;
                case 'prev':
                    tracker.log('CarouselPrev', {
                        url: new URL(window.location.href),
                        targetTracker: ['archiproTracker', 'ga4Tracker'],
                        data: {
                            ExtraData: JSON.stringify({
                                source: sectionSource,
                                libraryItemId: impression.itemID,
                            }),
                        },
                        ga4Data: {
                            source: sectionSource,
                            libraryItemId: impression.itemID,
                        },
                    });
                    break;
            }
        }
    };

    return (
        <ProjectTile
            ref={ref}
            {...rest}
            inViewport={beenInViewport}
            trackCarouselActivity={carouselTracker}
        />
    );
};

export default ProjectImpressionTile;
